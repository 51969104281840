import FacebookIcon from "@/assets/images/Facebook";
import GoogleIcon from "@/assets/images/GoogleIcon";
import Button from "@/components/common/Button/Button";
import CombinedLink from "@/components/common/CombinedLink/CombinedLink";
import { FormikInputBox } from "@/components/input/InputBox";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { signIn, SignInResponse } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useUserData } from "src/hooks/UserDataHook";
import { ParentRoles, StudentRoles } from "src/utils/constants";
import { createFormikConfig } from "src/utils/formik-helper";
import * as Yup from "yup";
import StudyluIcon from "public/icons/studylu-logo-text.svg";
import UserIcon from "public/icons/auth/account.svg";
import PwOnIcon from "public/icons/auth/on.svg";
import PwOffIcon from "public/icons/auth/off.svg";
import PwIcon from "public/icons/auth/pw.svg";

import type { NextPage } from "next";
import { CustPage } from "src/views/types";
import Link from "next/link";
import { NextSeo } from "next-seo";
export interface SignInViewProps {
  failedMsg?: string;
}

const useStyle = createUseStyles({
  facebook: {
    backgroundColor: "#3e5c9a",
  },
  google: {
    backgroundColor: "#df4b38",
  },
});

const ErrorMessageSpan = (props: { msg: string }) => {
  return (
    <div className={"flex flex-row items-center justify-center text-sm font-thin"}>
      <ExclamationCircleIcon
        className={"text-danger-500 inline-block "}
        width={24}
      />
      {props.msg}
    </div>
  );
};

export const SignInView: NextPage<SignInViewProps> & CustPage = (
  props: SignInViewProps
) => {
  const userData = useUserData();
  const { t, i18n } = useTranslation(["common"]);
  const router = useRouter();
  const classes = useStyle();
  const { error, callbackUrl } = router.query; //error: CredentialsSignin, NoPermission... https://next-auth.js.org/errors
  const formRef = useRef<FormikProps<any>>(null);
  const test = useMemo(
    () => formRef?.current?.errors ?? [],
    [formRef?.current?.errors]
  );
  const [signInRes, setSignInRes] = useState<SignInResponse>();







  const formik = createFormikConfig({
    enableReinitialize:true,
    validateOnBlur:false,
    validateOnChange:false,
    validateOnMount:false,
    initialValues: 
    {
      username:  "",
      password: "",
      rememberMe: false,
    }
    ,
    validationSchema: Yup.object().shape({
      username: Yup.string().required(t("common:requireUsername")),
      password: Yup.string().required(t("common:requirePassword")),
    }),
    onSubmit: async (values) => {
      formRef.current?.validateForm(values)

      const result = await signIn("credentials", {
        redirect: false,
        ...values,
        callbackUrl: "",
      });
      if(result?.ok){
        if(values.rememberMe){
          const loginCache={
            userName:values.username,
            password:values.password
          }
          await localStorage.setItem("loginCache",JSON.stringify(loginCache)) 
        }else{
          await localStorage.removeItem("loginCache")
        }
      }
      setSignInRes(result);
    },
  });


  useEffect(()=>{
    formRef.current&& getDefaultLoginCache()
  },[])

  const getDefaultLoginCache=async()=>{
    const loginCache=await localStorage.getItem("loginCache")
    if(loginCache&& formRef.current){
      const loginState = JSON.parse(loginCache)
      formRef.current?.setValues({
        username:loginState?.userName?.toString(),
        password:loginState?.password?.toString(),
        rememberMe:true
      })
    } 
  }
  


  useEffect(() => {
    if (userData && userData?.userInfo) {
      if (
        !!StudentRoles.find(
          (it) => (userData?.userInfo?.roles?.indexOf(it) ?? -1) >= 0
        )
      ) {
        router.push(callbackUrl?.toString() ?? "/student");
      } else if (
        !!ParentRoles.find(
          (it) => (userData?.userInfo?.roles?.indexOf(it) ?? -1) >= 0
        )
      ) {
        router.push(callbackUrl?.toString() ?? "/parent");
      } else {
        router.push(callbackUrl?.toString() ?? "/");
      }
    }
  }, [userData, router]);

  return (
    <div className="max-w-[30rem] mx-auto ">
      <NextSeo 
        noindex={false}
        nofollow={false}
        title={t("common:login")}
        description={t("common:login")}
      />
      <div className={clsx("text-center mb-12 ")}>
        <p
          className={clsx(
            "mb-2 text-[2.5rem] font-bold text-primary-500 font-[NotoSansTc] "
          )}
        >
          {t("common:welcomeMsg")}
        </p>
        <StudyluIcon className="mx-auto  h-16 w-auto" />
      </div>

      <Formik {...formik} innerRef={formRef}>
        <Form action="/api/TokenAuth/Authenticate" method="POST" >
          <div className={clsx("w-full  mx-auto  ")}>
            <div className={clsx("mb-3")}>
              <Field
                className=" w-full    rounded-xl  [&>div]:shadow-none "
                component={FormikInputBox("text")}
                startAdornments={
                  <UserIcon className="text-primary-500 w-11 h-11 pr-1" />
                }
                placeholder={t("common:username")}
                name="username"
                label=""
                inputProps={{className:" focus:ring-2 border-none rounded-xl bg-success-50 text-primary-500 placeholder:text-primary-500 ",
                  
                }}
              />
            </div>
            <div className={clsx("mb-3")}>
              <Field
                className=" w-full   rounded-xl [&>div]:shadow-none "
                inputProps={{className:" border-none focus:ring-2 rounded-xl bg-success-50 text-primary-500 placeholder:text-primary-500"}}
                component={FormikInputBox("password")}
                startAdornments={
                  <PwIcon
                    className="text-primary-500 w-11 h-11 pr-1"
                  />}
                placeholder={t("common:password")}
                name="password"
                label=""
                validateOnBlur={true}
              />
            </div>
            <div className={clsx("relative flex flex-row justify-between items-center mb-12")}>
              <div className={clsx("relative flex-row flex items-center gap-2")}>
                <Field
                  className={
                    " rounded   focus:ring-primary-500  border-primary-500 text-primary-500 outline-primary-500   overflow-hidden "
                  }
                  type="checkbox"
                  name="rememberMe"
                  id="rememberMe"
                  inputProps={{className:" " }}
                />
                <label className={"pl-1 text-base text-gray-400"} htmlFor="rememberMe">
                  {t("common:rememberMe")}
                </label>
              </div>
              <div className={clsx(" text-gray-400 text-base ")}>
                <Link href={"/auth/forgetPassword"}>
                  {t("common:forgotPassword")}
                </Link>
              </div>
            </div>
            <Button
              className={clsx("w-full  text-xl text-center rounded-3xl")}
              variant="default"
              type="submit"
            >
              {t("common:login")}
            </Button>
            
            {/**TODO: rework register page */}
            <div className={clsx("text-center hidden")}>
              <Link href={"/auth/register"}>
                {t("common:doNotHaveAccount")}
              </Link>
            </div>
            <div className={clsx("pt-3 text-center text-danger-500 ")}>
              {/* <ErrorMessage
                name="username"
                render={(msg) => <ErrorMessageSpan msg={msg} />}
              />
              <ErrorMessage
                name="password"
                render={(msg) => <ErrorMessageSpan msg={msg} />}
              /> */}
              {signInRes && signInRes.error == "CredentialsSignin" ? (
                <ErrorMessageSpan msg={t("common:CredentialsSignIn")} />
              ) : (
                <></>
              )}
              {signInRes && signInRes.error == "NoPermission" ? (
                <ErrorMessageSpan msg={t("common:NoPermission")} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </Form>
      </Formik>
      {/**TODO: add other login method */}
      <div className={clsx("text-center text-2xl hidden")}>
        <p className={clsx("p-3")}>{t("common:otherLogin")}</p>
        <Button
          variant="rounded"
          className={clsx("m-3", classes.facebook)}
          image={<FacebookIcon className="m-auto" />}
        />
        <Button
          variant="rounded"
          className={clsx("m-3", classes.google)}
          image={<GoogleIcon className="m-auto" />}
        />
      </div>
      {/* Error: { error } get the i18n string by error type */}
    </div>
  );
};
SignInView.layoutTemplate = "auth";
