import type { NextPage } from 'next'
import Head from 'next/head'
import Image from "next/legacy/image"
import styles from '@/styles/Home.module.css'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { SignInView } from 'src/views/Auth/SignIn/SignInView'
import { getCsrfToken, getSession } from 'next-auth/react';
import { ParentRoles, StudentRoles } from 'src/utils/constants';
export default SignInView

export async function getServerSideProps(context: any) {
  const { req } = context;
    
  const session = await getSession({ req });

  if (session) {
    return {
      redirect: { destination: ParentRoles.find(it=>session.user.role.indexOf(it)>=0)?"/parent": "/student" },
    };
  }
  return {
    props: {
      session: session,
      ...(await serverSideTranslations(context.locale, ['common'])),
      // csrfToken: await getCsrfToken(context)
      // Will be passed to the page component as props
    },
  };
}