import React from 'react';
import { Story } from '@storybook/react';

import Component, {InputBoxProps} from './InputBox';

const componentName = 'InputBox'

//Export the meta
const meta = {
  component: Component,
  title: componentName,
};
export default meta;

//Define the default layout
const Template: Story<InputBoxProps>  = (args) => <Component {...args}/>

//Define the styles here
export const Default = Template.bind({});
Default.args = {
  type:"text",
  inputProps:{
    placeholder:"test is good"
  },
  label:"label is good",
  errorMessage:"error message is not good",
  startAdornments:<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
  </svg>,
  endAdornments: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
  </svg>,
  disableErrorMessage: false
};